export const toolboxObj = {
  id: 'toolbox',
  lightBg: true,
  lightText: true,
  lightTextDesc: false,
  topLine: 'Languages & Skills',
  headLine: 'The Toolbox',
  skill1: `Javascript`, 
  skill2: `Python`,
  skill3: `HTML/CSS`,
  skill4: `Node.js`,
  skill5: `React`,
  skill6: `Django`,
  skill7: `PostgreSQL`,
  skill8: `MongoDB`,
  skill9: `RESTful APIs`,
  skill10: `Linux`,
  skill11: `Security Concepts`,
  skill12: `Websockets/Network Protocols`,
  buttonLabel: `To My Projects`,
  imgStart: true,
  dark: true,
  primary: true,
  darkText: true
}